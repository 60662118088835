import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <div>{children}</div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`This page is for all of the equipment and software that I use on a daily basis.`}</p>
    <p><em parentName="p">{`I’ll find a way to format all this better… soon.`}</em></p>
    <hr></hr>
    <h3>{`Workstation`}</h3>
    <p>{`Where I spend most of my time developing. My bread and butter.`}</p>
    <p><img parentName="p" {...{
        "src": "workstation.jpeg",
        "alt": "Image of Workstation"
      }}></img></p>
    <p><strong parentName="p">{`Monitor 1`}</strong>{` `}<inlineCode parentName="p">{`Dell S2719DGF`}</inlineCode>{` `}<strong parentName="p">{`Monitor 2`}</strong>{` `}<inlineCode parentName="p">{`LG 27GL850-B`}</inlineCode></p>
    <p><strong parentName="p">{`Motherboard`}</strong>{` `}<inlineCode parentName="p">{`MSI B450 Tomahawk MAX`}</inlineCode>{` `}<strong parentName="p">{`CPU`}</strong>{` `}<inlineCode parentName="p">{`Ryzen 3600X`}</inlineCode></p>
    <p><strong parentName="p">{`RAM`}</strong>{` `}<inlineCode parentName="p">{`64GB DDR4 3600 MHz`}</inlineCode></p>
    <p><strong parentName="p">{`GPU 1`}</strong>{` `}<inlineCode parentName="p">{`NVIDIA GTX 1070`}</inlineCode>{` `}<strong parentName="p">{`GPU 2`}</strong>{` `}<inlineCode parentName="p">{`Ryzen 5700XT`}</inlineCode></p>
    <p><strong parentName="p">{`Host OS`}</strong>{` `}<inlineCode parentName="p">{`Arch Linux`}</inlineCode>{` `}<strong parentName="p">{`Guest OS`}</strong>{` `}<inlineCode parentName="p">{`Windows 10`}</inlineCode></p>
    <p><strong parentName="p">{`Speakers`}</strong>{` `}<inlineCode parentName="p">{`EDIFIER R1700BT`}</inlineCode></p>
    <p><strong parentName="p">{`Keyboard`}</strong>{` `}<inlineCode parentName="p">{`Hexgears Supernova`}</inlineCode>{` `}<strong parentName="p">{`Mouse`}</strong>{` `}<inlineCode parentName="p">{`Mionix Castor`}</inlineCode></p>
    <hr></hr>
    <h3>{`Homelab`}</h3>
    <p>{`TBA soon… There’s a lot to go over.`}</p>
    <hr></hr>
    <h2>{`Everyday Hardware`}</h2>
    <h4>{`Headphones`}</h4>
    <p><inlineCode parentName="p">{`Apple Airpods Pro`}</inlineCode>{`
`}<inlineCode parentName="p">{`Hyper X Cloud Fight II`}</inlineCode>{`
`}<inlineCode parentName="p">{`Sony WH-1000XM3`}</inlineCode></p>
    <h4>{`Phone`}</h4>
    <p><inlineCode parentName="p">{`iPhone 12 Pro Max`}</inlineCode></p>
    <h4>{`Smartwear`}</h4>
    <p><inlineCode parentName="p">{`Apple Watch Series 3`}</inlineCode></p>
    <h4>{`Laptop`}</h4>
    <p><inlineCode parentName="p">{`Macbook Pro 15" (2017)`}</inlineCode></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      